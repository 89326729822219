@import "src/scss/design-variables";


.c-form-control-container {
  display: flex;
  margin-bottom: 10px;
}

.c-form-group {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 2px;
}

.c-input, .c-select {
  height: 56px;
  line-height: 1;
}

.c-select {
  display: flex !important;
  align-items: center !important;
  padding-left: 0;
}
