.g-btn-primary-outline {
	font-size: 15px;
	letter-spacing: 0.7px;
	padding: 15px;
	font-weight: 500;
	color: $primary;
	background: white;
	border-color: $primary;

	&:hover {
		//font-weight: 500;
		//background: map_get($design-purple, dark);
		//border-color: map_get($design-purple, dark);
	}


}