/*
 * ----------------------------------------
 * Palettes
 * ----------------------------------------
 */


$design-gray: (
	text: #444444,
	background: #fcfcfc,
	//border-light: #f5f5f5,
	border-light: #f5f4f8,
);


$design-purple: (
	default: #4f4394,
	dark: #181155,
	light: #8c84b7,
	lighter: #d2cfd4,
	dark-background: #d0cbeb,
	background: #f8f7ff,
	border: #ece9f7,
	smoked: #78777d,
);

$design-red: (
	default: #eb4d5c,
);


/*
 * ----------------------------------------
 * Sizes
 * ----------------------------------------
 */

$navbar-height: (
	desktop: 50px,
);

$font-size: (
	default: 16px,
	big: 22px,
	small: 14px,
);

$space: (
	xs: 5px,
	small: 10px,
	middle: 20px,
	medium: 45px,
	big: 60px,
);