$primary-color: #554696;
$primary-light: lighten($primary-color, 33%);   // #b4acd8
$secondary-color: #898989;
$secondary-dark: #979797;
$secondary-light: lighten($secondary-color, 40%);
$white: white;

$text: $secondary-color;
$text-light: lighten($text, 25%);

$bg-color: #fafafa;

$font-size-smallest: 10px;
$font-size-smaller: 14px;
$font-size-normal: 15px;
$font-size-bigger: 21px;

$padding-sm: 4px;
$padding-md: 8px;
$padding-lg: 20px;
$padding-xl: 20px;
$padding-xxl: 32px;

$margin-sm: $padding-sm;
$margin-md: $padding-md;
$margin-lg: $padding-lg;
$margin-xl: $padding-xl;
$margin-xxl: $padding-xxl;

$main-content-padding: $margin-xl;

$divider: 1px solid $secondary-light;


@import "mixins";
