
$font-family-sans-serif: Helvetica, sans-serif;
//$font-family-sans-serif: 'Rubik', Helvetica, sans-serif;

$primary: map_get($design-purple, default);

$body-color: var(--default);

/*
 * ----------------------------------------
 * Breakpoints
 * ----------------------------------------
 */

$grid-breakpoints: (
		xs: 0,
		sm: 576px,
		md: 768px,
		lg: 992px,
		xl: 1200px
) !default;
