/*
 * ----------------------------------------
 * Custom variables
 * ----------------------------------------
 */
// New
@import "src/scss/design-variables";

@import '@angular/cdk/overlay-prebuilt.css';

/*
* ----------------------------------------
* Bootstrap
* ----------------------------------------
*/

// Core bootstrap file
@import "bootstrap/scss/functions";

// Customized variables
@import "src/scss/bootstrap-variables";
@import "src/scss/old_styles/theme";

// Original variables
@import "bootstrap/scss/variables";

// Another core bootstrap file
@import "bootstrap/scss/mixins";

// Core styles
@import "bootstrap/scss/print";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/utilities";

// Used components
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/dropdown";
//@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/modal";
//@import "bootstrap/scss/list-group";
//@import "bootstrap/scss/card";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/close";

/*
 * ----------------------------------------
 * 3-rd parties
 * ----------------------------------------
 */
$pretty--color-primary: $primary;
$pretty--box-size: calc(1.5em + 2px);
@import 'pretty-checkbox/src/pretty-checkbox.scss';

.#{$pretty--class-name}.p-pulse:not(.p-switch) {
  input:checked~.state {
    label:before {
      animation: pulse 0.5s !important;
    }
  }
}

.#{$pretty--class-name} input {
  height: 1.8em !important;
}

/*
 * ----------------------------------------
 * Custom components
 * ----------------------------------------
 */

@import "src/scss/components/g-btn-bold";
@import "src/scss/components/g-info";
@import "src/scss/components/g-btn-primary-outline";

/*
 * ----------------------------------------
 * Overrides
 * ----------------------------------------
 */

@import "src/scss/general/body";
@import "src/scss/general/button";
@import "src/scss/general/form";
@import "src/scss/bootstrap/dropdown";
@import "src/scss/bootstrap/form";

@import "src/scss/customizable/index";

:root {
  --default: #444444;
  --light: #B3BBC7;
  --primary: #4F4394;
  --primary-light: #4F439490;
  --secondary: #18324C;
  --secondary: #18324C10;
  --background: #ffffff;
}

.custom-body {
  color: var(--default) !important;
}

/*
  Styling fixes for the nz-select component
  TODO: reevaluate whether these styles can be kept inside the component
 */
nz-select-top-control {
  height: 33px !important;
  border: none !important;
  padding-top: 3px !important;
  box-shadow: none !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #e9ecef !important;

}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding-left: 16px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 16px;
}

a[data-tooltip] {
  font-size: inherit !important;
  color: black !important;
  font-weight: inherit !important;
  text-decoration: underline !important;
  position: relative;
}

.c-app-content {
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    width: 576px !important;
    min-height: calc(100vh - #{map_get($space, middle)*2}) !important;
    margin: map_get($space, middle) 0 !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border-radius: map_get($space, xs);
    overflow: hidden;
  }
}

.small-container {
  max-width: 500px;
  margin: map_get($space, middle) auto;
  display: block;
  text-align: center;
  overflow: hidden;
}

.required-star {
  color: #e55039;
}

.c-category-header {
  display: flex;
  align-items: center;
  min-height: map_get($space, big);
  margin-top: -1px;
  margin-bottom: 30px;
  background: var(--secondary);
  cursor: pointer;
}

.c-category-title {
  flex-grow: 1;
  letter-spacing: 0.5px;
  font-size: 17px;
  color: var(--primary);
}

.c-category-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  min-width: 50px;
  fill: var(--primary);
}

/* ----------------------------------------
 * Group
 * -------------------------------------- */

.c-category {
  display: flex;
  flex-direction: column;
  position: relative;
}

.c-category-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  min-width: 85px;
  fill: var(--primary);
}

.c-category-title {
  flex-grow: 1;
  letter-spacing: 0.5px;
  font-size: 17px;
}

.c-category-content {
  overflow: hidden;
}

.c-badge {
  margin-left: map_get($space, xs);
  background: var(--primary);
  font-size: 14px;
  font-weight: 400;
}

.c-title {
  color: var(--primary);
}

.btn-primary {
  background-color: var(--primary);
  color: var(--background);
  border: var(--primary);

  &:not(:disabled):not(.disabled) {
    &:active, &:focus {
      background-color: var(--primary);
      color: var(--background);
      border: var(--primary);
    }
  }
}

.btn-primary:hover {
  background-color: var(--primary-light);
  color: var(--background);
  border: var(--primary-light);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--light);
  border: var(--light);
  opacity: 0.65;
}

.btn-link {
  color: var(--light);
  text-decoration: none;
}

/*
 * ----------------------------------------
 * Inputs
 * ----------------------------------------
 */

//input.form-control,
//textarea,
//textarea.form-control,
//.c-select,
//.form-control {
//  color: var(--default);
//  height: 40px;
//  border: 1px solid var(--default) !important;
//}

input.form-control:focus,
textarea:focus,
textarea.form-control:focus,
.c-select:focus,
.form-control:focus {
  color: var(--default);
  border: 2px solid var(--primary) !important;
  box-shadow: none !important;
}

input::placeholder {
  color: var(--light);
}

label,
.c-label {
  color: var(--light);
}

.c-entry {
  flex-grow: 1;
  font-size: map_get($font-size, default);
  letter-spacing: 0.3px;
  line-height: 1.2;
  color: var(--default);
  position: relative;
  margin-bottom: 0 !important;

  label {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.4px;
    position: absolute;
    top: -17px;
    line-height: 1;
    color: var(--light);
  }
}

.c-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  min-width: 45px;
  margin-right: map_get($space, middle);
  color: var(--primary);
  fill: var(--primary);
  margin-top: 4px;

  &.c-alight-top {
    margin-top: 56px;
    align-self: flex-start;
  }
}

.c-info-icon {
  margin-left: 10px;
  fill: var(--light)
}

.ant-tooltip-inner {
  background-color: var(--primary);
  color: var(--background);
}


.pretty.p-svg .state .svg {
  width: 1.3em !important;
  height: 1.3em !important;
  top: -5px !important;
}

.custom-checkbox .state.p-primary label:after {
  background-color: var(--background);
  border: 1px solid var(--primary);
}

.custom-checkbox input:checked~.state.p-primary label:after,
.custom-checkbox.p-toggle input:checked.state.p-primary label:after {
  /* background-color: #4f4394 !important; */
  background: var(--primary) !important;
  border: 1px solid var(--primary);
}
